<template>
  <div>
    <v-dialog width="80%" v-model="show">
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->
      <v-col cols="12" sm="12" md="12" lg="12" class="pa-0">
        <v-card class="pa-5">
          <vue-core-video-player
            :src="video"
            :autoplay="true"
            v-if="video && value"
            @ended="handleEnded"
            @play="playFunc"
          ></vue-core-video-player>
          <v-divider></v-divider>
        </v-card>
      </v-col>
    </v-dialog>

    <v-dialog width="60%" v-model="showSignupCTA">
      <v-col cols="12" sm="12" md="12" lg="12" class="pa-0">
        <v-card class="pa-5">
          <v-row class="text-center d-flex justify-center">
            <v-col class="mt-6 mb-10" cols="10">
              <h2 class="font-weight-bold headline pb-2">Vedi Dear Mama</h2>
              <p class="font-weight-light body-2">
                Richiedi uno screening del documentario integrale Dear Mama
              </p>
              <v-btn to="/contact" class="mt-5" outlined color="#222222"
                >Richiedi uno screening</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "VideoPlayerInDialog",

  data() {
    return {
      showSignupCTA: false,
    };
  },

  props: {
    value: Boolean,
    video: {
      default: () => ({}),
    },
    videotitle: {
      default: () => ({}),
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    handleEnded() {
      this.showSignupCTA = true;
    },
    playFunc() {
      // GA4 event: play video
      this.$gtag.query("event", "trailer_video_play", {
        trailer_video_title: "dear_mama_trailer_hd",
      });
    },
  },
};
</script>